import { useGlobalContext } from 'hooks';
import { PlusIcon, FolderPlusIcon, XMarkIcon, TrashIcon } from '@heroicons/react/24/solid';
import * as styles from 'components/global/styles';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'flowbite-react';

export const JSONViewer = ({ lang, open, onClickHandler }) => {
  const store = useGlobalContext();
  const { t } = useTranslation(['home']);
  return (
    <div
      className={`${
        open ? '' : 'hidden'
      } absolute inset-x-0 top-0 w-full h-full bg-dimBlack text-white flex flex-col justify-center items-center py-8 z-50`}
    >
      <button
        type="button"
        className="absolute top-0 right-0 z-50 w-8 h-8 cursor-pointer hover:bg-red-400"
        onClick={() => onClickHandler()}
      >
        <XMarkIcon />
      </button>
      <div className="container mx-auto w-full h-full grid lg:grid-cols-2 lg:grid-rows-1 grid-cols-1 grid-rows-2 gap-4 overflow-hidden">
        {/* Charity Info */}
        <div className="col-span-1 row-span-1 flex flex-col justify-center items-center bg-slate-700 rounded-lg border-2 border-slate-500">
          <div
            className={`w-full ${styles.roundTop} ${styles.textColorHeader} ${styles.bgDark} border-b-2 border-b-slate-500 font-bold flex justify-between items-center gap-2 pl-3`}
          >
            <h1 className="font-bold">{t('charity')}</h1>
            <Tooltip content={t('addInfo')} trigger="hover">
              <button
                type="button"
                className="w-8 h-8 font-bold bg-greenBG hover:bg-greenBD text-green-200 hover:text-greenDark border-2 border-greenBD p-1 m-2 flex justify-center items-center cursor-pointer rounded-md"
                onClick={() => store.newCharityInfo()}
              >
                <PlusIcon />
              </button>
            </Tooltip>
          </div>
          <div className="w-full h-full flex flex-col justify-start items-start p-2 gap-2 overflow-y-auto">
            <div className="bg-slate-800 w-full flex flex-col justify-center items-start px-2 pr-4 pb-2 rounded-lg border-2 border-slate-500">
              <h1 className="w-full flex flex-col justify-center items-start p-2 font-bold capitalize text-yellow-500">
                {t('name')}
              </h1>
              <div className="w-full mb-1 flex justify-center items-center">
                <label className="w-9 p-2" for="nameEN">
                  EN
                </label>
                <input
                  placeholder={store.translations.en.charity.name}
                  defaultValue={store.translations.en.charity.name}
                  onChange={e => store.updateCharity('name', e.target.value, 'en', 0)}
                  type="text"
                  id="nameEN"
                  name="nameEN"
                  className="w-full bg-slate-600 p-2 rounded-lg"
                />
              </div>
              <div className="w-full mb-1 flex justify-center items-center">
                <label className="w-9 p-2" for="nameFR">
                  FR
                </label>
                <input
                  placeholder={store.translations.fr.charity.name}
                  defaultValue={store.translations.fr.charity.name}
                  onChange={e => store.updateCharity('name', e.target.value, 'fr', 0)}
                  type="text"
                  id="nameFR"
                  name="nameFR"
                  className="w-full bg-slate-600 p-2 rounded-lg"
                />
              </div>
            </div>
            {store.translations.en.charity.info.length > 0 &&
              store.translations.en.charity.info.map((i, idx) => (
                <div className="w-full bg-slate-800 px-2 pr-4 pb-2 rounded-lg border-2 border-slate-500">
                  <div
                    key={`label${idx}`}
                    className="w-full flex flex-col justify-center items-start"
                  >
                    <div className="w-full bg-slate-800 flex justify-between items-center py-2">
                      <h1 className="font-bold capitalize text-yellow-500 pl-2">
                        {t('label')} {idx + 1}
                      </h1>
                      <Tooltip content={t('removeInfo')} trigger="hover">
                        <button
                          type="button"
                          className="bg-redBG hover:bg-redBD w-8 h-8 text-red-200  hover:text-redDark border-2 border-redBD p-1 flex justify-center items-center cursor-pointer rounded-md"
                          onClick={() => store.removeCharityInfo(idx)}
                        >
                          <TrashIcon />
                        </button>
                      </Tooltip>
                    </div>
                    <div className="w-full mb-1 flex justify-center items-center overflow-hidden">
                      <label className="w-9 p-2" for={`info${idx}`}>
                        EN
                      </label>
                      <input
                        placeholder={store.translations.en.charity.info[idx].label}
                        defaultValue={store.translations.en.charity.info[idx].label}
                        onChange={e => store.updateCharity('infoLabels', e.target.value, 'en', idx)}
                        type="text"
                        id={`info${idx}`}
                        name={`info${idx}`}
                        className="w-full bg-slate-600 p-2 rounded-lg"
                      />
                    </div>
                    <div className="w-full mb-1 flex justify-center items-center overflow-hidden">
                      <label className="w-9 p-2" for={`info${idx}`}>
                        FR
                      </label>
                      <input
                        placeholder={store.translations.fr.charity.info[idx].label}
                        defaultValue={store.translations.fr.charity.info[idx].label}
                        onChange={e => store.updateCharity('infoLabels', e.target.value, 'fr', idx)}
                        type="text"
                        id={`info${idx}`}
                        name={`info${idx}`}
                        className="w-full bg-slate-600 p-2 rounded-lg"
                      />
                    </div>
                  </div>
                  <div
                    key={`info${idx}`}
                    className="w-full flex flex-col justify-center items-start"
                  >
                    <h1 className="w-full bg-slate-800 flex flex-col justify-center items-start p-2 font-bold capitalize text-yellow-500">
                      {t('description')} {idx + 1}
                    </h1>
                    <div className="w-full mb-1 flex justify-center items-center overflow-hidden">
                      <label className="w-9 p-2" for={`info${idx}`}>
                        EN
                      </label>
                      <textarea
                        placeholder={store.translations.en.charity.info[idx].description}
                        defaultValue={store.translations.en.charity.info[idx].description}
                        onChange={e => store.updateCharity('info', e.target.value, 'en', idx)}
                        type="text"
                        id={`info${idx}`}
                        name={`info${idx}`}
                        className="w-full min-h-[128px] bg-slate-600 p-2 rounded-lg"
                      />
                    </div>
                    <div className="w-full mb-1 flex justify-center items-center overflow-hidden">
                      <label className="w-9 p-2" for={`info${idx}`}>
                        FR
                      </label>
                      <textarea
                        placeholder={store.translations.fr.charity.info[idx].description}
                        defaultValue={store.translations.fr.charity.info[idx].description}
                        onChange={e => store.updateCharity('info', e.target.value, 'fr', idx)}
                        type="text"
                        id={`info${idx}`}
                        name={`info${idx}`}
                        className="w-full min-h-[128px] bg-slate-600 p-2 rounded-lg"
                      />
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
        {/* Sponsor Info */}
        <div className="col-span-1 row-span-1 flex flex-col justify-start items-center bg-slate-700 rounded-lg border-2 border-slate-500">
          <div
            className={`w-full ${styles.roundTop} ${styles.textColorHeader} ${styles.bgDark} border-b-2 border-b-slate-500 font-bold flex justify-between items-center gap-2 pl-3`}
          >
            <h1 className="font-bold">{t('sponsors')}</h1>
            <Tooltip content={t('addSponsor')} trigger="hover">
              <button
                type="button"
                className="w-8 h-8 font-bold bg-greenBG hover:bg-greenBD text-green-200 hover:text-greenDark border-2 border-greenBD p-1 flex justify-center items-center cursor-pointer rounded-md m-2"
                onClick={() => store.newSponsor()}
              >
                <PlusIcon />
              </button>
            </Tooltip>
          </div>
          <div className="w-full flex flex-col justify-start items-start p-2 gap-2 overflow-y-auto">
            {store.translations.en.sponsors.sponsors.length > 0 &&
              store.translations.en.sponsors.sponsors.map((i, idx) => (
                <div className="w-full bg-slate-800 rounded-lg border-2 border-slate-500">
                  <div className="w-full flex flex-row justify-between items-center p-2 border-b-2 border-b-slate-500 px-4">
                    <h1 className="font-bold text-yellow-500">
                      {store.translations[lang].sponsors.sponsors[idx].name}
                    </h1>
                    <div className="flex gap-2">
                      <Tooltip content={t('removeSponsor')} trigger="hover">
                        <button
                          type="button"
                          className="w-8 h-8 font-bold bg-redBG hover:bg-redBD text-red-200 hover:text-redDark border-2 border-redBD p-1 flex justify-center items-center cursor-pointer rounded-md"
                          onClick={() => store.removeSponsor(idx)}
                        >
                          <TrashIcon />
                        </button>
                      </Tooltip>
                      <Tooltip content={t('addSponsorInfo')} trigger="hover">
                        <button
                          type="button"
                          className="w-8 h-8 font-bold bg-greenBG hover:bg-greenBD text-green-200 hover:text-greenDark border-2 border-greenBD p-1 flex justify-center items-center cursor-pointer rounded-md"
                          onClick={() => store.newSponsorInfo(idx)}
                        >
                          <FolderPlusIcon />
                        </button>
                      </Tooltip>
                    </div>
                  </div>
                  <div
                    key={idx}
                    className="w-full flex flex-col justify-center items-start px-2 pr-4 pb-2 border-b-2 border-b-slate-500"
                  >
                    <div className="w-full flex justify-between items-center">
                      <h1 className="w-full flex flex-col justify-center items-start p-2 font-bold capitalize text-yellow-500">
                        {t('sponsor')} {t('name')} {idx + 1}
                      </h1>
                    </div>
                    <div className="w-full mb-1 flex justify-center items-center">
                      <label className="w-9 p-2" for="snameEN">
                        EN
                      </label>
                      <input
                        placeholder={store.translations.en.sponsors.sponsors[idx].name}
                        defaultValue={store.translations.en.sponsors.sponsors[idx].name}
                        onChange={e => store.updateSponsor('name', e.target.value, 'en', idx, 0)}
                        type="text"
                        id="snameEN"
                        name="snameEN"
                        className="w-full bg-slate-600 p-2 rounded-lg"
                      />
                    </div>
                    <div className="w-full mb-1 flex justify-center items-center">
                      <label className="w-9 p-2" for="snameFR">
                        FR
                      </label>
                      <input
                        placeholder={store.translations.fr.sponsors.sponsors[idx].name}
                        defaultValue={store.translations.fr.sponsors.sponsors[idx].name}
                        onChange={e => store.updateSponsor('name', e.target.value, 'fr', idx, 0)}
                        type="text"
                        id="snameFR"
                        name="snameFR"
                        className="w-full bg-slate-600 p-2 rounded-lg"
                      />
                    </div>
                  </div>
                  {i.info.length > 0 &&
                    i.info.map((i2, idx2) => (
                      <div
                        key={idx2}
                        className="w-full flex flex-col justify-center items-start gap-2 px-2 pr-4 pb-2"
                      >
                        <div className="w-full flex justify-between items-center pt-2">
                          <h1 className="font-bold capitalize text-yellow-500 pl-2">
                            {t('sponsor')} {idx + 1} {t('info')} {idx2 + 1}
                          </h1>
                          <Tooltip content={t('removeSponsorInfo')} trigger="hover">
                            <button
                              type="button"
                              className="bg-redBG hover:bg-redBD w-8 h-8 text-red-200 hover:text-redDark border-2 border-redBD p-1 flex justify-center items-center cursor-pointer rounded-md"
                              onClick={() => store.removeSponsorInfo(idx, idx2)}
                            >
                              <TrashIcon />
                            </button>
                          </Tooltip>
                        </div>
                        <div className="w-full mb-1 flex justify-center items-center">
                          <label className="w-9 p-2" for={`sinfoEN${idx2}`}>
                            EN
                          </label>
                          <textarea
                            placeholder={store.translations.en.sponsors.sponsors[idx].info[idx2]}
                            defaultValue={store.translations.en.sponsors.sponsors[idx].info[idx2]}
                            onChange={e =>
                              store.updateSponsor('info', e.target.value, 'en', idx, idx2)
                            }
                            type="text"
                            id={`sinfoEN${idx2}`}
                            name={`sinfoEN${idx2}`}
                            className="w-full min-h-[128px] bg-slate-600 p-2 rounded-lg"
                          />
                        </div>
                        <div className="w-full mb-1 flex justify-center items-center">
                          <label className="w-9 p-2" for={`sinfoFR${idx2}`}>
                            FR
                          </label>
                          <textarea
                            placeholder={store.translations.fr.sponsors.sponsors[idx].info[idx2]}
                            defaultValue={store.translations.fr.sponsors.sponsors[idx].info[idx2]}
                            onChange={e =>
                              store.updateSponsor('info', e.target.value, 'fr', idx, idx2)
                            }
                            type="text"
                            id={`sinfoFR${idx2}`}
                            name={`sinfoFR${idx2}`}
                            className="w-full min-h-[128px] bg-slate-600 p-2 rounded-lg"
                          />
                        </div>
                      </div>
                    ))}
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export const SetupModal = ({ lang, open, onClickHandler }) => {
  const store = useGlobalContext();
  const { t } = useTranslation(['home']);
  return (
    <div
      className={`${
        open ? '' : 'hidden'
      } absolute inset-x-0 top-0 w-full h-full bg-dimBlack text-white flex flex-col justify-center items-center py-8 z-50`}
    >
      <button
        type="button"
        className="absolute top-0 right-0 z-50 w-8 h-8 cursor-pointer hover:bg-red-400"
        onClick={() => onClickHandler()}
      >
        <XMarkIcon />
      </button>
      <div className="w-full h-full flex justify-center items-center">
        <div className="w-1/3 h-auto bg-slate-900 p-4">
          <h1 className="pb-4 text-3xl">Fundky Admin</h1>
          <div className="w-full mb-1 flex justify-center items-center">
            <label className="w-8" for="campID">
              ID:
            </label>
            <input
              placeholder={store.fundky.id}
              defaultValue={store.fundky.id}
              onChange={e => store.setFundkyID(parseInt(e.target.value))}
              type="number"
              id="campID"
              name="campID"
              className="w-full bg-slate-600 p-2 rounded-lg"
            />
          </div>
          <div className="w-full mb-1 flex justify-center items-center">
            <label className="w-8" for="bgID">
              BG:
            </label>
            <input
              placeholder={store.fundky.pollid}
              defaultValue={store.fundky.pollid}
              onChange={e => store.setBackgroundID(parseInt(e.target.value))}
              type="number"
              id="bgID"
              name="bgID"
              className="w-full bg-slate-600 p-2 rounded-lg"
            />
          </div>
          <div className="w-full mb-1 flex justify-center items-center">
            <label className="w-8" for="frID">
              FR:
            </label>
            <input
              placeholder={store.fundky.frameid}
              defaultValue={store.fundky.frameid}
              onChange={e => store.setFrameID(parseInt(e.target.value))}
              type="number"
              id="frID"
              name="frID"
              className="w-full bg-slate-600 p-2 rounded-lg"
            />
          </div>
          <div className="w-full mb-1 flex justify-center items-center">
            <label className="w-8" for="onSite">
              $:
            </label>
            <input
              placeholder={store.onSite}
              defaultValue={store.onSite}
              onChange={e => store.setDonations(parseFloat(e.target.value))}
              type="number"
              id="onSite"
              name="onSite"
              className="w-full bg-slate-600 p-2 rounded-lg"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
